import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from 'react-router-dom';

const axios = require('axios');

export default function App() {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/redirect">
            <AuthCheck />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

class Login extends React.Component {

    client_id = "7ssVB4VsLrbomw";
    state_string = "test string";
    redirect_uri = (process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:3000/redirect' : 'https://feede.sheline.me/redirect');
    scope_string = "read vote";
    
    // TODO: Use /api/v1/authorize.compact? for smaller screens
    auth_url = `https://www.reddit.com/api/v1/authorize?client_id=${this.client_id}&response_type=code&state=${this.state_string}&redirect_uri=${this.redirect_uri}&duration=permanent&scope=${this.scope_string}`;

    handleClick = () => {
      window.location = this.auth_url;
    }

    render() {
        return (
          <div id='login'>
            <h1>feede</h1>
            <button className='btn btn-highlight' onClick={this.handleClick}>
                <img src='assets/images/reddit_icon.png' alt='Reddit icon' width='32' />
                <span className='px-2'>Sign in with Reddit</span>
            </button>
          </div>
        );
    }
}

class AuthCheck extends React.Component {

  state = { loading_data: true };

  constructor(props) {
    super(props);
    this.code = (new URLSearchParams(window.location.search)).get('code');
  }

  componentDidMount() {
    // Retrieve access token
    if (this.state.loading_data) {
      fetch(`${(process.env.NODE_ENV === 'development' ? 'http://localhost:5000/feede-a9c88/us-central1/app/api/access-token' : 'https://us-central1-feede-a9c88.cloudfunctions.net/app/api/access-token')}?code=${this.code}`)
      .then(response => {
        return response.text();
      })
      .then(text => {
        // Parse response
        console.log(text);
        let response = JSON.parse(text);

        // Store tokens
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token);

        // Update state
        this.setState(() => ({ loading_data: false }));
      });
    }
  }

  render() {
    if (this.state.loading_data) {
      return(<h1>Loading...</h1>)
    } else {
      return <Redirect to='/' />
    }
  }
}

class Home extends React.Component {

  posts = [];
  state = { loading_data: true };

  constructor (props) {
    super(props);

    // Show homepage items
    this.getHomepage();
  }

  getHomepage() {
    if (this.state.loading_data) {
      axios.get('https://oauth.reddit.com/.json?limit=100', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
      .then(response => {
        response.data.data.children.forEach((post, i) => {
          this.posts.push(new Post({
            post: post.data,
            index: i
          }));
        });

        // Update state
        this.setState(() => ({ loading_data: false }));
      }); 
    }
  }

  render() {
    if (this.state.loading_data) {
      return (<h1>Loading...</h1>);
    } else {
      return (
        <div className='container' id='posts'>
          <div className='row justify-content-center'>
            <div className='col-lg-7'>
              <h6 className='text-center mt-4 mb-2'>r/home</h6>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-7'>
            { this.posts.map(function(post){ return(<Post key={post.index} {...post} />) }) }
            </div>
          </div>
        </div>
      );
    }
  }
}

class Post extends React.Component {

  state = {
    vote: 'unvoted',
    color: 'accent',
    img_display: 'd-none',
    video_display: 'd-none',
    audio: '',
    loading_data: true
  };

  constructor(props) {
    super(props);

    this.index = props.index;
    this.post = props.post;

    if (this.index%2===0) { this.state.color='highlight' }
  }

  componentDidMount() {
    this.setState({
      vote: (this.post.likes ? 'upvoted' : (this.post.likes==null ? 'unvoted' : 'downvoted'))
    });
  }

  handleClickVideo(e) {
    let video = document.getElementById(`video-${e.index}`);
    let audio = document.getElementById(`audio-${e.index}`);
    if (video.paused) { 
      video.play(); 
      if (audio) audio.play();
    }
    else { 
      video.pause(); 
      if (audio) audio.pause();
    }
  }

  handleShowImage(e) {
    e.setState({ img_display: e.state.img_display==='' ? 'd-none' : '' });
  }

  handleShowVideo(e) {
    e.setState({ video_display: e.state.video_display==='' ? 'd-none' : '' });
  }

  handleVote(e) {
    let vote;
    switch (e.state.vote) {
      case 'unvoted':
        vote = 'upvoted';
        break;
      case 'upvoted':
        vote = 'downvoted';
        break;
      default:
        vote = 'unvoted';
        break;
    }
    
    // Update state
    let old_vote = this.state.vote;
    e.setState(() => ({ vote: vote }));

    // Send request to vote
    fetch('https://oauth.reddit.com/api/vote', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `dir=${vote==='unvoted' ? 0 : (vote==='upvoted' ? '1' : '-1')}&id=t3_${e.post.id}&rank=2`
    })
    .then(response => {
      // Revert vote icon if request failed
      if (response.status!==200) {
        e.setState(() => ({ vote: old_vote }));
      }
    }); 
  }

  render() {
    var selftext = ''
    if (this.post.selftext!=='') selftext = <p className='selftext' >{this.post.selftext}</p>

    var media = ''
    if (this.post.preview) {
      if (this.post.is_video) {
        var audio_url = this.post.media.reddit_video.scrubber_media_url.split('/');
        audio_url.pop();
        audio_url = audio_url.join('/') + '/audio';

        if (this.state.loading_data) {
          axios.get(this.post.media.reddit_video.hls_url, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
              'Content-Type': 'binary/octet-stream'
            }
          })
          .then(response => {
            if (response.data.includes('AUDIO')) {
              axios.get(audio_url, { 
                responseType: 'arraybuffer',
                headers: { 'accept': 'video/mp4' } 
              })
              .then(response => {
                this.setState({ audio: (
                  <audio id={`audio-${this.index}`}>
                    <source src={URL.createObjectURL(new Blob([response.data], {type : 'video/mp4'}))} />
                  </audio>
                ),
                loading_data: false });
              });
            }
          });
        }

        media = (
          <div className='media w-100 mt-3'>
            <video
              id={`video-${this.index}`}
              className={`${this.state.video_display}`}
              preload="metadata"
              poster={this.post.preview.images[0].source.url.replace(/&amp;/g, '&')}
              onClick={() => this.handleClickVideo(this)}>

              <source src={this.post.media.reddit_video.scrubber_media_url} />
              { this.state.audio }
              <p>Your browser does not support the video tag.</p>

            </video>
            <p className='video-toggle my-2' onClick={() => this.handleShowVideo(this)}>{ this.state.video_display==='' ? 'Hide video' : 'Show video' }</p>
          </div>
        );
        
      } else {
        media = (
          <div className='media w-100'>
            <img src={this.post.preview.images[0].source.url.replace(/&amp;/g, '&')} className={`${this.state.img_display}`} alt={`Media of Reddit post #${this.post.id}`} />
            <p className='img-toggle my-2' onClick={() => this.handleShowImage(this)}>{ this.state.img_display==='' ? 'Hide image' : 'Show image' }</p>
          </div>
        );
      }
    }
    return (
      <div key={this.index} className='post my-3 w-100'>    
          <div className='header'>
            <div>
              <p className='subreddit mb-0'>{this.post.subreddit_name_prefixed}</p>
              <p className='title'>{this.post.title}</p>
            </div>
            <img src={`assets/images/${this.state.vote}_${this.state.color}.png`}
                alt={`Vote icon. Current state: ${this.state.vote}`}
                className='vote'
                onClick={() => this.handleVote(this)}></img>
          </div>
          { selftext }
          { media }
      </div>
    );
  }
}